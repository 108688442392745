<template>
    <v-dialog v-model="dialog" max-width="650" content-class="rounded-lg">
        <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" class="group flex items-center rounded-md text-blue-500 text-sm font-medium py-2 ml-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span>Añadir ajuste</span>
            </button>
        </template>
        <v-form v-model="isValid" @submit.prevent="save" ref="form">
            <v-card>
                <v-card-title class="text-h5 justify-space-between">
                    <strong> {{ actionText }} ajuste</strong>
                    <v-btn @click="dialog = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <label for="reason" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Razón de ajuste</label>
                            <v-text-field v-model="item.reason" :rules="rules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <label for="amount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cantidad</label>
                            <v-text-field v-model.number="item.amount" :rules="numberRules" dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="py-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="cancel" depressed class="text-none spacing-none rounded-lg mr-3">Cancelar</v-btn>
                    <v-btn :disabled="!isValid" type="submit" depressed color="#3b82f6" class="text-none spacing-none white--text rounded-lg">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
export default {
    name: 'Invoice',
    data: () => ({
        dialog: false,
        isValid: false,
        isEdit: false,
        tmpItem: {},
        item: {
            reason: '',
            amount: 0,
        },
        rules: [
            v => !!v                || 'Field is required',
        ],
        numberRules: [
            v => !!v || v === 0     || 'Field is required',
            v => !isNaN(v)          || 'Field must be a valid number',
        ],
    }),
    mounted() {
        this.$root.$on('editAdjustment', (item) => {
            this.tmpItem    = JSON.parse(JSON.stringify(item))
            this.item       = item
            this.isEdit     = true
            this.dialog     = true
        })
    },
    methods: {
        save() {
            if (!this.isEdit) {
                const item = JSON.parse(JSON.stringify(this.item))
                this.$root.$emit('addAdjustment', item)
            }
            this.dialog = false
        },
        cancel() {
            if (this.isEdit) {
                for (const key of Object.keys(this.item)) {
                    this.item[key] = this.tmpItem[key]
                }
            }

            this.dialog = false
        }
    },
    computed: {
        actionText: ({ isEdit }) => isEdit ? 'Editar' : 'Añadir'
    },
    watch: {
        dialog(v) {
            if (!v && this.isEdit) {
                this.isEdit = false
            }
        }
    }
}
</script>
