<template>
    <v-dialog v-model="dialog" max-width="750" content-class="rounded-lg">
        <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" class="group flex items-center rounded-md text-blue-500 text-sm font-medium py-2 ml-3">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span>Añadir línea</span>
            </button>
        </template>
        <v-form v-model="isValid" @submit.prevent="save" ref="form">
            <v-card>
                <v-card-title class="text-h5 justify-space-between">
                    <strong> {{ actionText }} linea de invoice</strong>
                    <v-btn @click="dialog = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="pb-0">
                            <label for="from" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Desde</label>
                            <v-text-field v-model="item.from" type="date" :rules="rules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                            <label for="to" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Hasta</label>
                            <v-text-field v-model="item.to" type="date" :rules="rules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                            <label for="site" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Site</label>
                            <v-text-field v-model="item.site" :rules="rules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                            <label for="apartment" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Apartamento</label>
                            <v-text-field v-model="item.apartment" :rules="rules" dense></v-text-field>
                        </v-col>
                        <!--  -->
                        <v-col cols="12" sm="4" class="pb-0">
                            <label for="guests" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Huéspedes</label>
                            <v-text-field v-model.number="item.numberOfGuests" :rules="numberRules" dense v-mask="'###'"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="pb-0">
                            <label for="totalReceived" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Total Recibido</label>
                            <v-text-field v-model.number="item.totalReceived" :rules="numberRules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="pb-0">
                            <label for="serviceDiscount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Descuento servicios</label>
                            <v-text-field v-model.number="item.serviceDiscount" :rules="optionalNumberRules" dense></v-text-field>
                        </v-col>
                        <!--  -->
                        <v-col cols="12" sm="4" class="pb-0">
                            <label for="commission" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">% Comisión</label>
                            <v-text-field v-model.number="item.commissionPercentage" :rules="commissionRules" dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="pb-0">
                            <label for="extra" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Extra</label>
                            <v-text-field v-model.number="item.extra" :rules="optionalNumberRules" dense></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="py-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="cancel" depressed class="text-none spacing-none rounded-lg mr-3">Cancelar</v-btn>
                    <v-btn type="submit" :disabled="!isValid" depressed color="#3b82f6" class="text-none spacing-none white--text rounded-lg">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
    name: 'Invoice',
    data: () => ({
        dialog: false,
        isValid: false,
        isEdit: false,
        tmpItem: {},
        item: {
            from: new Date().toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
            site: '',
            apartment: '',
            numberOfGuests: null,
            totalReceived: 0,
            serviceDiscount: 0,
            extra: 0,
            commissionPercentage: 15
        },
        rules: [
            v => !!v                || 'Field is required',
        ],
        numberRules: [
            v => ( !!v && v !== 0 ) || 'Field is required',
            v => !isNaN(v)          || 'Field must be a valid number',
        ],
        optionalNumberRules: [
            v => !isNaN(v)          || 'Field must be a valid number',
        ],
        commissionRules: [
            v => !!v || v === 0     || 'Commission is required',
            v => !isNaN(v)          || 'Commission must be a valid  number',
            v => v >= 0 && v <= 100 || 'Commission must be a value between 0 and 100',
        ],
    }),
    mounted() {
        this.$root.$on('editLine', (item) => {
            this.tmpItem    = JSON.parse(JSON.stringify(item))
            this.item       = item
            this.isEdit     = true
            this.dialog     = true
        })
    },
    methods: {
        save() {
            if (!this.isEdit) {
                const item = JSON.parse(JSON.stringify(this.item))
                this.$root.$emit('addInvoiceLine', item)
            }
            
            this.close();
        },
        cancel() {
            if (this.isEdit) {
                for (const key of Object.keys(this.item)) {
                    this.item[key] = this.tmpItem[key]
                }
            }

            this.close();
        },
        close() {
            this.dialog = false;
            this.item = {
                from: new Date().toISOString().slice(0, 10),
                to: new Date().toISOString().slice(0, 10),
                site: '',
                apartment: '',
                numberOfGuests: null,
                totalReceived: 0,
                serviceDiscount: 0,
                extra: 0,
                commissionPercentage: 15
            };
        }
    },
    computed: {
        actionText: ({ isEdit }) => isEdit ? 'Editar' : 'Añadir'
    },
    watch: {
        dialog(v) {
            if (!v) {
                if (this.isEdit) { 
                    this.isEdit = false
                }
                this.item = {
                    from: new Date().toISOString().slice(0, 10),
                    to: new Date().toISOString().slice(0, 10),
                    site: '',
                    apartment: '',
                    guests: null,
                    totalReceived: 0,
                    serviceDiscount: 0,
                    extra: 0,
                    commissionPercentage: 15
                }
                this.$refs.form.resetValidation()
            }
        }
    },
    directives: {
        mask
    }
}
</script>
