<template>
    <v-row>
        <v-col cols="12">
            <div class="text-h5 font-weight-bold d-flex justify-space-between">
                Crear invoice
                <v-btn @click="save" :loading="loading" :disabled="!isValid" depressed color="#3b82f6" class="text-none spacing-none white--text rounded-lg">
                    <v-icon left>mdi-floppy</v-icon>
                    Guardar
                </v-btn>
            </div>
        </v-col>
        <v-col cols="12" sm="4">
            <label for="name" class="block mt-3 mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nombre de cliente/localización</label>
            <v-text-field v-model="invoice.name" dense></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-data-table :items="invoice.lines" :headers="headers" :items-per-page="-1" hide-default-footer dense :class="{ 'mb-3': invoice.lines.length }">
                <template v-slot:[`item.apartment`]="{ item }">
                    {{ item.site }} - {{ item.apartment }} - {{item.numberOfGuests }} persona(s)
                </template>
                <template v-slot:[`item.dates`]="{ item }">
                    {{ item.from }} / {{ item.to }}
                </template>
                <template v-slot:[`item.totalReceived`]="{ item }">
                    {{ formatNumber(item.totalReceived) }}
                </template>
                <template v-slot:[`item.serviceDiscount`]="{ item }">
                    {{ formatNumber(item.serviceDiscount) }}
                </template>
                <template v-slot:[`item.extra`]="{ item }">
                    {{ formatNumber(item.extra) }}
                </template>
                <template v-slot:[`item.commission`]="{ item }">
                    {{ getCommission(item, true) }}
                </template>
                <template v-slot:[`item.total`]="{ item }">
                    {{ getLineTotal(item, true) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu offset-y rounded="lg" min-width="150">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list dense class="my-0 py-0">
                            <v-list-item @click="$root.$emit('editLine', item)">
                                <v-icon small class="mr-2">mdi-square-edit-outline</v-icon>
                                <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteItem(item)">
                                <v-icon small color="red darken-1" class="mr-2">mdi-close-thick</v-icon>
                                <v-list-item-title>Eliminar</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:[`no-data`]>
                    <span></span>
                </template>
            </v-data-table>
            <invoice-line></invoice-line>
            <v-divider class="my-3"></v-divider>
        </v-col>
        <v-col cols="12">
            <div class="d-flex justify-space-between">
                <strong>Subtotal</strong>
                <span>{{ subTotalFormatted }}</span>
            </div>
            <div v-for="(a, i) in invoice.adjustments" :key="`adjustment-${i}`" class="d-flex justify-space-between">
                <div>
                    <small>{{ a.reason }}</small>
                    <v-btn @click="invoice.adjustments.splice(i, 1)" icon small>
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </div>
                <small class="text-red-500">({{ formatNumber(a.amount) }})</small>
            </div>
            <adjustment></adjustment>
        </v-col>
        <v-col cols="12">
            <v-divider class="my-3"></v-divider>
            <div class="d-flex justify-space-between">
                <strong>Total</strong>
                <span>{{ total }}</span>
            </div>
        </v-col>
        <v-col cols="12" class="mt-5">
            <v-divider class="my-3"></v-divider>
            <div class="d-flex justify-space-between mb-3">
                <strong>Notas</strong>
                <v-chip small class="caption">{{ `${invoice.notes.length} nota${invoice.notes.length == 1 ? '' : 's'}` }}</v-chip>
            </div>

            <template v-for="(note, ix) in invoice.notes">
                <div :key="ix" class="my-2">
                    <pre>{{ note.note }}</pre>

                    <div class="d-flex justify-space-between my-3">
                        <span class="caption grey--text">{{ note.createdAt }}</span>
                        <v-chip small class="caption" :color="note.visibleToCustomer ? 'primary' : 'warning'">{{ note.visibleToCustomer ? 'Visible' : 'Interno' }}</v-chip>
                        <span class="caption grey--text">By ???</span>
                    </div>
                    <v-divider inset></v-divider>
                </div>
            </template>

            <v-textarea v-model="note" outlined rows="3" count hide-details=""></v-textarea>
            <v-checkbox v-model="noteVisibleToCustomer" label="Visible para el cliente"></v-checkbox>

            <div class="text-right">
                <v-btn @click="addNote" depressed color="secondary" class="text-none spacing-none white--text rounded-lg"
                    :disabled="note == null || note == undefined || note.length == 0">
                    Añadir nota
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import { formatNumber, getCommission, getLineTotal } from '@/assets/modules'
import InvoiceLine from '@/components/InvoiceLine'
import Adjustment from '@/components/Adjustment'

export default {
    name: 'CreateInvoice',
    components: { InvoiceLine, Adjustment },
    props: { 
        id: {
            type: String,
            default: null,
        } 
    },
    data: () => ({
        loading: false,
        created: false,
        note: null,
        noteVisibleToCustomer: false,

        invoice: {
            name: '',
            lines: [],
            adjustments: [],
            notes: [],
        },
        headers: [
            { text: 'Item', value: 'apartment', class: 'bg-gray-50 rounded-left-lg' },
            { text: 'Desde/hasta', value: 'dates', class: 'bg-gray-50'  },
            { text: 'Recibido', value: 'totalReceived', class: 'bg-gray-50'  },
            { text: 'Descuento por servicios', value: 'serviceDiscount', class: 'bg-gray-50'  },
            { text: 'Extra', value: 'extra', class: 'bg-gray-50'  },
            { text: 'Comisión', value: 'commission', class: 'bg-gray-50'  },
            { text: 'Total', value: 'total', class: 'bg-gray-50'  },
            { text: '', value: 'actions', sortable: false, width: '1%', class: 'bg-gray-50' },
        ],
    }),
    mounted() {
        if( this.id != -1 && this.id != null && this.id != undefined ) {
            // load invoice information
            this.getInvoice();
        }

        this.$root.$on('addInvoiceLine', (item) => {
            this.invoice.lines.push(item)
        })
        this.$root.$on('addAdjustment', (item) => {
            this.invoice.adjustments.push(item)
        })
    },
    beforeRouteLeave (to, from , next) {
        if (this.created) { 
            return next()
        }

        const answer = window.confirm('¿De verdad quieres irte? ¡Usted tiene cambios no guardados!')
        if (answer) {
            next()
        } else {
            next(false)
        }
    },
    methods: {
        formatNumber,
        getCommission,
        getLineTotal,
        getInvoice() {
            this.loading = true;
			this.$axios.get(`/invoices/${this.id}`)
            .then((res) => {
				this.invoice = res.data.invoice
            })
            .finally(() => { 
                this.loading = false
            })
        },
        addNote() {
            if( this.note == null || this.note == undefined || this.note.length == 0 ) {
                return;
            }

            this.invoice.notes.push({
                id: -1,
                note: this.note,
                invoiceId: this.id,
                visibleToCustomer: this.noteVisibleToCustomer,
                createdAt: new Date().toISOString().slice( 0, 10 ),
                updatedAt: new Date().toISOString().slice( 0, 10 )
            });
            this.note = null;
        },
        save() {
            if( this.id == null || this.id == undefined || this.id.length == 0 || isNaN( parseInt( this.id ) ) || this.id == -1 ) {
                this.create();
                return;
            }

            this.edit();
        },
        create() {
            if (!this.isValid) return;
            this.loading = true;
            this.$axios.post('/invoices', { invoice: this.invoice })
            .then((res) => {
                this.created    = true
                const id        = res.data.invoice.id
                this.$router.push(`/${id}`)
            })
            .finally(() => {
                this.loading = false
            })

        },
        edit() {
            if (!this.isValid) return;
            this.loading = true;
            this.$axios.put('/invoice', { invoice: this.invoice })
            .then((res) => {
                this.created    = true
                const id        = res.data.invoice.id
                this.$router.push(`/${id}`)
            })
            .finally(() => {
                this.loading = false
            })
        },
        deleteItem(item) {
            const i = this.invoice.lines.indexOf(item)
            if (i >= 0) {
                this.invoice.lines.splice(i, 1)
            }
        }
    },
    computed: {
        subTotal: ({ invoice }) => invoice.lines.reduce((a, b) =>  a + (getLineTotal(b) || 0), 0),
        adjustmentTotal: ({ invoice }) => invoice.adjustments.reduce((a, b) =>  a + (parseFloat( b.amount ) || 0), 0),
        subTotalFormatted: ({ subTotal })   => formatNumber(subTotal),
        total: ({ subTotal, adjustmentTotal }) => formatNumber(subTotal - adjustmentTotal),
        isValid:({ invoice, adjustmentTotal }) => invoice.name.trim() && invoice?.lines.length && !isNaN(adjustmentTotal),
    }
}
</script>
<style scoped>
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>